dmx.Formatters('global', {

    // default(value:Any, defaultValue:Any):Any
    default: function(value, defaultValue) {
        return value == null ? defaultValue : value;
    },

    // string(value:Any):String
    string: function(value) {
        return String(value);
    },

    // number(value:Any):String
    number: function(value) {
        return Number(value);
    },

    // date(value:Any):String
    date: function(value) {
        if (typeof value == 'number') {
            value *= 1000;
        }

        if (typeof value == 'string' && value.toLowerCase() == 'now') {
            value = null;
        }

        var date = value ? new Date(value) : new Date();

        if (date.toString() == 'Invalid Date') {
            return null;
        }

        return date.toISOString();
    }

});

dmx.Formatters('boolean', {

    // default():Boolean
    default: function(bool) {
        return bool;
    },

    // toString():String
    toString: function(bool) {
        return String(bool);
    },

    // toNumber():String
    toNumber: function(bool) {
        return Number(bool);
    },

    // then(trueValue:Any, falseValue:Any):Any
    then: function(bool, trueValue, falseValue) {
        return bool ? trueValue : falseValue;
    }

});

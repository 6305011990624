dmx.Formatters('object', {

    // keys():Array
    keys: function(object) {
        return Object.keys(object);
    },

    // values():Array
    values: function(object) {
        return Object.keys(object).map(function(key) {
            return object[key];
        });
    },

    // hasKey(key:String):Boolean
    hasKey: function(object, key) {
        return Object.keys(object).indexOf(key) !== -1;
    },

    // hasValue(value:Any):Boolean
    hasValue: function(object, value) {
        return Object.keys(object).map(function(key) {
            return object[key];
        }).indexOf(value) !== -1;
    },

    //getValueOrKey():any
    getValueOrKey: function(object, key) {
        return object[key] != null ? object[key] : key;
    },

    // default():Object
    default: function(object) {
        return object;
    }

});
